import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "platform" }
const _hoisted_3 = { class: "platform-id" }
const _hoisted_4 = {
  key: 0,
  class: "platform-message platform-invalid"
}
const _hoisted_5 = {
  key: 1,
  class: "platform-message platform-prerelease"
}
const _hoisted_6 = { class: "platform-icon" }
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  key: 2,
  class: "platform-version"
}
const _hoisted_9 = {
  key: 3,
  class: "platform-rating"
}
const _hoisted_10 = {
  key: 4,
  class: "platform-release"
}
const _hoisted_11 = {
  key: 5,
  class: "platform-removed-store"
}
const _hoisted_12 = {
  key: 6,
  class: "platform-link"
}
const _hoisted_13 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.platform)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, [
            _createTextVNode("ID: "),
            _createElementVNode("strong", null, _toDisplayString(_ctx.platform.id), 1)
          ]),
          (_ctx.platform.hidden)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Hidden"))
            : _createCommentVNode("", true),
          (_ctx.platform.isPrerelease)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, "Pre-Release"))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("img", {
              src: _ctx.platform.icon,
              alt: ""
            }, null, 8, _hoisted_7)
          ]),
          (_ctx.platform.lastVersion || _ctx.platform.developer)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.platform.developer) + " - " + _toDisplayString(_ctx.platform.lastVersion), 1))
            : _createCommentVNode("", true),
          (_ctx.platform.rating)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, " ★ " + _toDisplayString(_ctx.platform.rating ? _ctx.platform.rating.toFixed(2) : "?"), 1))
            : _createCommentVNode("", true),
          (_ctx.platform.releaseDate || _ctx.platform.releaseDateRaw)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.platform.releaseDateRaw
            ? _ctx.platform.releaseDateRaw
            : _ctx.platform.releaseDate.split("-").reverse().join(".")), 1))
            : _createCommentVNode("", true),
          (_ctx.platform.removedFromStore)
            ? (_openBlock(), _createElementBlock("span", _hoisted_11, " Removed from Store "))
            : (_ctx.platform.url)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createElementVNode("a", {
                    href: _ctx.platform.url,
                    target: "_blank"
                  }, "Open Store", 8, _hoisted_13)
                ]))
              : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}