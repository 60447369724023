
import { defineComponent, onMounted } from "vue";

import {
  BaseButton,
  CountButton,
  DataTable,
  DeleteIcon,
  EditIcon,
  NorthIcon,
  SouthIcon,
} from "@tager/admin-ui";
import { useDataTable } from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";
import {
  useResource,
  useResourceDelete,
  useResourceMove,
} from "@tager/admin-services";

import {
  getCategoriesCreateUrl,
  getCategoriesUpdateUrl,
  getGamesListUrl,
  getUsersListUrl,
} from "@/utils/paths";
import { getUserFeatureFlags } from "@/modules/users/services";
import { getGames } from "@/modules/games/services";

import { deleteCategory, getCategoriesList, moveCategory } from "../services";
import { CategoryInterface } from "../typings";

import { COLUMN_DEFS } from "./CategoriesListView.helpers";

export default defineComponent({
  name: "CategoriesListView",
  components: {
    CountButton,
    DeleteIcon,
    EditIcon,
    NorthIcon,
    SouthIcon,
    BaseButton,
    Page,
    DataTable,
  },
  setup() {
    const {
      fetchEntityList: fetchData,
      isLoading: isDataLoading,
      rowData: data,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<CategoryInterface>({
      fetchEntityList: (params) =>
        getCategoriesList({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
        }),
      initialValue: [],
      resourceName: "Categories List",
    });

    const { isMoving, handleResourceMove } = useResourceMove({
      moveResource: moveCategory,
      resourceName: "Category",
      onSuccess: fetchData,
    });

    const { isDeleting, handleResourceDelete } = useResourceDelete({
      deleteResource: deleteCategory,
      resourceName: "Category",
      onSuccess: fetchData,
    });

    function isBusy(id: number): boolean {
      return isDeleting(id) || isMoving(id) || isDataLoading.value;
    }

    return {
      columnDefs: COLUMN_DEFS,
      rowData: data,
      isRowDataLoading: isDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      isBusy,
      handleResourceDelete,
      handleResourceMove,
      getCategoriesCreateUrl,
      getCategoriesUpdateUrl,
      getGamesListUrl,
      getUsersListUrl,
    };
  },
});
