import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-13144cb6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "filters" }
const _hoisted_2 = { class: "checkbox-filters" }
const _hoisted_3 = { class: "developer-filter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormFieldMultiSelect = _resolveComponent("FormFieldMultiSelect")!
  const _component_FormFieldSelect = _resolveComponent("FormFieldSelect")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_AdvancedSearch = _resolveComponent("AdvancedSearch")!
  const _component_GamesListViewPlatform = _resolveComponent("GamesListViewPlatform")!
  const _component_EditIcon = _resolveComponent("EditIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_DeleteIcon = _resolveComponent("DeleteIcon")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    title: "Games",
    "header-buttons": [{ text: 'New Game', href: _ctx.getGamesCreateUrl() }]
  }, {
    default: _withCtx(() => [
      _createVNode(_component_DataTable, {
        "column-defs": _ctx.columnDefs,
        "row-data": _ctx.rowData,
        loading: _ctx.isRowDataLoading,
        "error-message": _ctx.errorMessage,
        "search-query": _ctx.searchQuery,
        pagination: {
        pageNumber: _ctx.pageNumber,
        pageCount: _ctx.pageCount,
        pageSize: _ctx.pageSize,
        disabled: _ctx.isRowDataLoading,
      },
        sort: { options: _ctx.sortOptions, value: _ctx.sortValue },
        onChange: _ctx.handleChange
      }, {
        filters: _withCtx(() => [
          _createVNode(_component_AdvancedSearch, {
            tags: _ctx.tags,
            "onClick:tag": _ctx.handleTagRemove
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_FormFieldMultiSelect, {
                  "selected-options": _ctx.categoryFilter,
                  "onUpdate:selectedOptions": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.categoryFilter) = $event)),
                  options: _ctx.categoryOptionList,
                  name: "categoryFilter",
                  label: "Genres",
                  "container-class": "filter"
                }, null, 8, ["selected-options", "options"]),
                _createVNode(_component_FormFieldMultiSelect, {
                  "selected-options": _ctx.tagFilter,
                  "onUpdate:selectedOptions": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tagFilter) = $event)),
                  options: _ctx.tagOptionList,
                  name: "tagFilter",
                  label: "Categories",
                  "container-class": "filter"
                }, null, 8, ["selected-options", "options"])
              ]),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_FormFieldSelect, {
                  value: _ctx.storesFilter,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.storesFilter) = $event)),
                  name: "stores",
                  label: "Stores",
                  options: _ctx.storesFilterOptions
                }, null, 8, ["value", "options"]),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_FormField, {
                    value: _ctx.developerFilter,
                    "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.developerFilter) = $event)),
                    name: "developer",
                    label: "Developer"
                  }, null, 8, ["value"])
                ])
              ])
            ]),
            _: 1
          }, 8, ["tags", "onClick:tag"])
        ]),
        "cell(android)": _withCtx(({ row }) => [
          _createVNode(_component_GamesListViewPlatform, {
            platform: row.android
          }, null, 8, ["platform"])
        ]),
        "cell(ios)": _withCtx(({ row }) => [
          _createVNode(_component_GamesListViewPlatform, {
            platform: row.ios
          }, null, 8, ["platform"])
        ]),
        "cell(actions)": _withCtx(({ row }) => [
          _createVNode(_component_BaseButton, {
            variant: "icon",
            title: "Редактировать",
            disabled: _ctx.isBusy(row.id),
            href: _ctx.getGamesUpdateUrl(row.id)
          }, {
            default: _withCtx(() => [
              _createVNode(_component_EditIcon)
            ]),
            _: 2
          }, 1032, ["disabled", "href"]),
          _createVNode(_component_BaseButton, {
            variant: "icon",
            title: "Delete",
            disabled: _ctx.isBusy(row.id) || !row.canDelete,
            onClick: ($event: any) => (_ctx.handleResourceDelete(row.id))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DeleteIcon)
            ]),
            _: 2
          }, 1032, ["disabled", "onClick"])
        ]),
        _: 1
      }, 8, ["column-defs", "row-data", "loading", "error-message", "search-query", "pagination", "sort", "onChange"])
    ]),
    _: 1
  }, 8, ["header-buttons"]))
}