import {
  FileType,
  Nullable,
  request,
  ResponseBody,
} from "@tager/admin-services";

import { TagInterface, TagFullInterface } from "./typings";

export interface CountData {
  count: number;
}

export function getTagsCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/tags/count" });
}

export function getTagsList(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<TagInterface>>> {
  return request.get({ path: "/admin/tags", params });
}

export function getTag(
  id: string | number
): Promise<ResponseBody<TagFullInterface>> {
  return request.get({ path: `/admin/tags/${id}` });
}

export interface TagCreateOrUpdatePayload {
  name: string;
  nameDe: string;
  nameEs: string;
  nameFr: string;
  nameIt: string;
  namePt: string;
  namePtBr: string;
  section: string;
  sectionEs: string;
  sectionDe: string;
  sectionIt: string;
  sectionFr: string;
  sectionPt: string;
  sectionPtBr: string;

  iconFile: string | null;
  iconSymbol: string | null;
  igdbAliases: string;
  appMagicIds: number[];
}

export function createTag(
  payload: TagCreateOrUpdatePayload
): Promise<ResponseBody<TagFullInterface>> {
  return request.post({
    path: `/admin/tags`,
    body: payload,
  });
}

export function updateTag(
  id: number | string,
  payload: TagCreateOrUpdatePayload
): Promise<ResponseBody<TagFullInterface>> {
  return request.put({
    path: `/admin/tags/${id}`,
    body: payload,
  });
}

export function deleteTag(id: number | string): Promise<{ success: boolean }> {
  return request.delete({ path: `/admin/tags/${id}` });
}

export function moveTag(
  id: number | string,
  direction: "up" | "down"
): Promise<{ success: boolean }> {
  return request.post({ path: `/admin/tags/${id}/move/${direction}` });
}

export function setTagAsCategory(
  id: number | string
): Promise<ResponseBody<{ id: number }>> {
  return request.post({ path: `/admin/tags/${id}/set-as-category` });
}
